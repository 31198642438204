<template>
  <div>
    <label
      v-if="props.label"
      :for="props.id"
      class="block text-sm font-medium leading-6"
    >{{ props.label }}</label>
    <div :class="[label ? 'mt-2' : '']">
      <Field
        v-slot="{ field, errors }"
        v-model="value"
        :name="name"
        :rules="props.rules"
        :placeholder="props.placeholder"
      >
        <textarea
          :id="props.id"
          v-bind="field"
          :rows="props.rows"
          :name="name"
          :placeholder="props.placeholder"
          class="block w-full input-field py-1.5 sm:text-sm sm:leading-6"
          :class="{
            'input-field-error': errors?.length || errorMessage,
            'input-field-success': isSuccess
          }"
        />
        <p
          v-if="errors?.length || errorMessage"
          class="text-xs text-red-hot mt-2"
        >
          {{ errorMessage || errors[0] }}
        </p>
      </Field>
    </div>
  </div>
</template>

<script setup>
import {
  useField,
  Field,
  useIsFieldValid,
  useIsFormTouched
} from 'vee-validate'
import uniqueId from 'lodash/uniqueId'
const props = defineProps({
  required: {
    type: Boolean,
    default: false
  },
  rules: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: uniqueId('app-input-name-')
  },
  id: {
    type: String,
    default: uniqueId('app-input-id-')
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  rows: {
    type: Number,
    default: 4
  }
})

const name = toRef(props, 'name')

const { value, errorMessage } = useField(() => name.value, props.rules, {
  syncVModel: true
})
const isValid = useIsFieldValid(name)
const isTouched = useIsFormTouched(name)
// const isDirty = useIsFieldDirty(name)
const isSuccess = computed(() => isTouched.value && isValid.value)
</script>
